import { Component, OnInit, EventEmitter } from '@angular/core';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { Output } from '@angular/core';

@Component({
  selector: 'app-text-common-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css']
})
export class TextCommonEditorComponent implements OnInit {
  blured = false
  focused = false;
  content = '';

  @Output() editorText: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {

  }

  setValue(value) {
    this.content = value;
  }

  onContentChanged(){
    this.editorText.emit(this.content);

  }

  created(event) {
    // tslint:disable-next-line:no-console
    
  }

  changedEditor(event: EditorChangeContent | EditorChangeSelection) {
    // tslint:disable-next-line:no-console
    // 
    // this.editorText.emit(this.content);
    this.editorText.emit(this.content);

  }

  focus($event) {
    // tslint:disable-next-line:no-console
    
    this.focused = true
    this.blured = false
    this.editorText.emit(this.content);

  }

  blur($event) {
    // tslint:disable-next-line:no-console
    
    this.focused = false
    this.blured = true;
    this.editorText.emit(this.content);
  }
}



