import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { PathkindService } from 'src/app/services/pathkind.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { API_PATH } from 'src/app/utility/reference/api-constant';
import { AccessioningService } from '../../accessioning/accessioning.service';

@Component({
  selector: 'app-add-remarks',
  templateUrl: './add-remarks.component.html',
  styleUrls: ['./add-remarks.component.css']
})
export class AddRemarksComponent implements OnInit {
  addRemarkForm: FormGroup
  apiConstant: any = API_PATH;
  patientWiseTestList: any;
  showName: boolean = true;

  constructor(
    public service: PathkindService,
    private toaster: ToasterService,
    private route: Router,
    private commonService: CommonService,
    private AccService: AccessioningService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddRemarksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data, "DATA")
    // if(this.data.modulename == 'lo'){
    //   this.setTestGuid = ''
    // }
    this.buildAddRemarksForm()
    this.getPatientWiseTestList();
  }

  buildAddRemarksForm() {
    this.addRemarkForm = this.fb.group({
      testGuid: [((this.data.modulename != 'lo') ? this.data?.testGuid : ""), Validators.required],
      remarks: ['', Validators.required],
      patientGuid: [''],
      modulename: ['',],
      testsampleGuid: ['',],
    })
  }
  reset(){
    this.addRemarkForm.get('remarks').setValue('');
  }
  getPatientWiseTestList() {
    if(this.data.modulename == 'lo'){
      this.showName = false
      console.log(this.data)
    }
    let request = {
      data: {
        // patientsampleGuid: this.data?.patientsampleGuid ? this.data?.patientsampleGuid : '',
        patientsampleGuid: (this.data?.modulename != 'lo') ? this.data?.patientsampleGuid:  '',
        patientGuid: this.data?.patientGuid ? this.data?.patientGuid : ''
      }
    }
    this.service.postServiceIp(this.apiConstant.PATIENT_WISE_TEST_LIST, request).subscribe(
      res => {
        if (res['statusCode'] === "0") {
          if (res['data'] && res['data']._lsttest && res['data']._lsttest.length > 0) {
            this.patientWiseTestList = res['data']._lsttest
          }
        }
      },
      error => {
        this.toaster.showError(error.error.Message);
      }
    )
  }

  AddRemark() {
    console.log(this.data, "when data is added") 
    this.addRemarkForm.value.testGuid = (this.data.modulename != 'lo') ? this.data.testGuid : this.addRemarkForm.value.testGuid;
    this.addRemarkForm.value.testsampleGuid = this.data.testsampleGuid;
    this.addRemarkForm.value.modulename = this.data.modulename;
    this.addRemarkForm.value.patientGuid = this.data.patientGuid

    if (this.addRemarkForm.invalid) {
      this.toaster.showInfo(this.service.formFillMessage)
    } else {
      console.log('hi kundan', this.addRemarkForm)
      this.service.postServiceIp(this.apiConstant.ADD_REMARKS, { data: this.addRemarkForm.value }).subscribe(
        res => {
          if (res['statusCode'] === "0") {
            this.onYesClick()
          } else {
            this.onNoClick()
          }
        },
        error => {
          this.toaster.showError(error.error.Message);
        }
      )
    }
  }

  onNoClick(): void {
    this.dialogRef.close('No');
  }

  onYesClick() {
    this.dialogRef.close('Yes');

  }

  public errorHandling(control: string, error: string) {
    return this.addRemarkForm.controls[control].hasError(error);
  }
}
