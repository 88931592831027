import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'actualDateFormat'
})
export class ActualDateFormatPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): Date {
    var parts = value.split('/');
    
    
    var actualDate = new Date(parts[2]+'-'+parts[1]+'-'+parts[0]);
    
    return actualDate;
  }

}
