import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-receipt-preview-print',
  templateUrl: './receipt-preview-print.component.html',
  styleUrls: ['./receipt-preview-print.component.css']
})
export class ReceiptPreviewPrintComponent implements OnInit {
  data=[
    {Category:'Routine',Discount:'1'}
  ] 
  constructor() { }

  ngOnInit(): void {
  }


  print() {
    window.print();
  }
}
