<!--Get OTP-->
<div class="common-dialog delete-confirmation">
<form [formGroup]="otpForm" (ngSubmit)="verify()" novalidate>
    <div class="header">
    <div class="row">
        <div class="col-md-12">  
			<h1>Enter Verification Code</h1>
			<p>Enter the 6 digit verification code {{sentResend}} to {{data?.mobilenomask}}<br/></p>          
        </div>
        <div class="col-md-1">
            <button mat-icon-button mat-dialog-close (click)="close('noVerify')" color="mat-primary"><mat-icon>close</mat-icon></button>
        </div>
    </div> 
</div>
<div class="modal-body">
    <div class="row">
		<div class="col-md-12 mt-2">
			<mat-form-field class="example-full-width w-100">
				<mat-label>Enter OTP</mat-label>
			<input type="text" formControlName="otp" maxlength="6" minlength="6" autocomplete="off" matInput required>
			<mat-error *ngIf="errorHandling('otp','required')">
				This Field is required
			</mat-error>
		</mat-form-field>
		</div>
	  </div>
</div> 
<div class="modal-footer">
	<p-button type="button" label="Resend" (click)="resend()" styleClass="button-border mr-1"></p-button>
	<p-button type="submit" label="Verify" styleClass="save-button"></p-button>
</div> 
</form>
</div>