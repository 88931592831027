<!--PAN CARD Confirmation-->
<div class="common-dialog delete-confirmation">
    <div class="header">
    <div class="row">
        <div class="col-md-10">  
            <!-- <h1>Confirmation</h1> -->
        </div>
        <div class="col-md-1">
            <button (click)="onNoClick()" mat-icon-button mat-dialog-close color="mat-primary"><mat-icon>close</mat-icon></button>
        </div>
    </div> 
</div>
<div class="modal-body d-flex align-items-center justify-content-center">
    <p class="mb-0 font-15">{{data.msg}}</p>
    <!-- <p class="mb-0 font-15">Please add PAN card. It is mandatory for this client type</p> -->
</div> 
<div class="modal-footer">
    <button pButton  name="no" type="cancel" (click)="onNoClick()" label="No" class="button-border mr-1" ></button>
    <button pButton  name="yes" type="save" (click)="onYesClick()" label="Back To Center Info" class="save-button"></button>
</div> 
</div>