import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { PathkindService } from 'src/app/services/pathkind.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { API_PATH } from 'src/app/utility/reference/api-constant';

@Component({
  selector: 'app-view-report-result-content',
  templateUrl: './view-report-result-content.component.html',
  styleUrls: ['./view-report-result-content.component.css']
})
export class ViewReportResultContentComponent implements OnInit {
  viewEditorDiv: HTMLElement;
  popupHeader: any = '';
  apiConstant: any = API_PATH;
  previewData: any = [];
  public sanitizer: DomSanitizer;
  
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewReportResultContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public service: PathkindService,
    private toaster: ToasterService
  ) { }
  ngOnInit(): void {
    this.getPreviewPreAuthData();
  }

  getPreviewPreAuthData() {
    let url: string;
    let request: any = {};
    if (this.data?.isVisitIDClick) {
      url = this.apiConstant.GET_PREVIEW_PREAUTH_VISITID;
      request = {
        patientGuid: this.data?.html?.patientGuid
      }
    } else {
      url = this.apiConstant.GET_PREVIEW_PREAUTH_BARCODE;
      request = {
        patientGuid: this.data?.html?.patientGuid,
        _lstTestSampleType: this.data?.html?._lstTestSampleType
      }
    }

    this.service.postService(url, { Data: request }).subscribe(res => {
      if (res['statusCode'] === "0") {
        this.previewData = res['data'] ? res['data'] : [];
      } else {
        this.previewData = [];
      }
    }, error => {
      this.toaster.showError(error?.error?.Message);
    })
  }

  // getPreviewPreAuthBarcode(){
  //   let request = {
  //     patientGuid: this.data?.html?.patientGuid
  //   }
  //   this.service.postService(this.apiConstant.GET_PREVIEW_PREAUTH_BARCODE, { Data: request }).subscribe(res => {
  //     if (res['statusCode'] === "0") {
  //       this.previewData = res['data'] ? res['data'] : [];
  //     } else {
  //       this.previewData = [];
  //     }
  //   }, error => {
  //     this.toaster?.showError(error?.error?.Message);
  //   })
  // }

  // getPreviewData() {
  //   if (this.data.viewFor == 'CustomRefRange') {
  //     this.viewEditorDiv = document.getElementById('viewEditorHtml');
  //     this.viewEditorDiv.innerHTML = this.data.html;
  //     this.popupHeader = 'View Custom Reference Range';
  //   } else if (this.data.viewFor == 'Authenticated') {
  //     this.viewEditorDiv = document.getElementById('viewEditorHtml');
  //     this.viewEditorDiv.innerHTML = this.data.html;
  //     this.popupHeader = 'View Result';
  //   }
  // }

  close(): void {
    this.dialogRef.close('yes');
  }
}
