import { Component, OnInit, EventEmitter,Inject } from '@angular/core';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import { Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css']
})
export class TextEditorComponent implements OnInit {
  blured = false
  focused = false;
  content = '';

  @Output() editorText: EventEmitter<string> = new EventEmitter<string>();
  constructor(public dialogRef: MatDialogRef<TextEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    
    this.content = this.data.result;
  }

  setValue(value) {
    this.content = value;
  }

  onContentChanged(){
    this.editorText.emit(this.content);

  }

  created(event) {
    // tslint:disable-next-line:no-console
    
  }

  changedEditor(event: EditorChangeContent | EditorChangeSelection) {
    // tslint:disable-next-line:no-console
    // 
    // this.editorText.emit(this.content);
    this.editorText.emit(this.content);

  }

  focus($event) {
    // tslint:disable-next-line:no-console
    
    this.focused = true
    this.blured = false
    this.editorText.emit(this.content);

  }

  blur($event) {
    // tslint:disable-next-line:no-console
    
    this.focused = false
    this.blured = true;
    this.editorText.emit(this.content);
  }

  //EVENT
  close(): void {
    this.dialogRef.close(null);
  }

  add(){
    this.dialogRef.close({content:this.content});

  }
}
