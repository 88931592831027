import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-rolling-client-pan-card-confirmation',
  templateUrl: './rolling-client-pan-card-confirmation.component.html',
  styleUrls: ['./rolling-client-pan-card-confirmation.component.css']
})
export class RollingClientPanCardConfirmationComponent implements OnInit {
 
  constructor(public dialogRef: MatDialogRef<RollingClientPanCardConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
    console.log(this.data)
    }
  
  
    onNoClick(): void {
      this.dialogRef.close('NO');
    }
  
    onYesClick(){
      this.dialogRef.close('YES');
  
    }

}
