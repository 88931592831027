import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment'
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PathkindService {
  // userId : string;
  IPAddress: any;
  version: any;
  menuGuid: string;
  formFillMessage: string = 'Please fill form.'
  savedMessage: string = 'Record Saved Successfully';
  updateMessage: string = 'Record Updated Successfully';
  statusMessage: string = 'Status Updated Successfully';
  documentUploadMessage: string = 'File is Upload Successfully'
  errorMessage: string = 'Record Saved Successfully';
  recordsPerPage: number = 10;
  recordForDos: number = 6;
  rowsPerPageDos :any[]=[6,12,30, 60]

  rowsPerPage :any[]=[10,20,50,100]
  // rowsPerPage :any[]=[10,20,50,{showAll:'All'}]
  currentDate = new Date(new Date(new Date().setDate(new Date().getDate()-1)).getTime()+19800000);
  backDate = new Date(new Date(new Date().setDate(new Date().getDate()-1)).getTime()+19800000);
  currentDateReport= new Date((new Date()).getTime()+19800000);
  backDateReport = new Date(new Date(new Date().setDate(new Date().getDate()-1)).getTime()+19800000);
  currentDateReportMIS= new Date((new Date()).getTime()+19800000); // USE IN MIS
  backDateReportMIS = new Date((new Date()).getTime()+19800000); // USE IN MIS
  currentDateMachineInterface= new Date((new Date()).getTime()+19800000); // USE IN MIS
  backDateReportMachineInterface = new Date(new Date(new Date().setDate(new Date().getDate()-14)).getTime()+19800000); // USE IN MIS
  filterMsg = 'Please use the Export Excel function to get data for the selected date range'
  exelMsg = 'Kindly select one month data at one go'
  public userId = new BehaviorSubject(null);
  public isReLoad = new BehaviorSubject(null);
  masterNameMaxLen = 255;
  masterCodeMaxLen = 50;
  masterRemarksMaxLen = 500;
  data: any;
  constructor(private http: HttpClient, private router: Router,
    private handler: HttpBackend) {
      this.httpclient = new HttpClient(handler);
    this.IPAddress = "192.168.2.25";
    this.version = "";
    let userData = JSON.parse(sessionStorage.getItem('userData'));
    if (userData) {
      this.userId.next(userData.data.userGuid);
    }

   this.data = JSON.parse(localStorage.getItem('data'))
   console.log(this.data, "this.data this.data this.data--- ")
  }

  signOut() {
    
    sessionStorage.removeItem('userData');
    this.userId.next(null);
    sessionStorage.removeItem('menuGuid');
    sessionStorage.removeItem('menus');
    sessionStorage.removeItem('menuData');
    sessionStorage.removeItem('liveCallContactNo');
    sessionStorage.removeItem('crmUserData');
    this.router.navigate(['/login']);
  }

  logOut(){
    
    sessionStorage.removeItem('userData');
    this.userId.next(null);
    sessionStorage.removeItem('menuGuid');
    sessionStorage.removeItem('menus');
    sessionStorage.removeItem('menuData');
    sessionStorage.removeItem('liveCallContactNo');
  }


  getService(url, params) {
    return this.http.get(environment.basePath + url, {
      params: params
    })
  }

  getMenuGuid() {
    let menu = JSON.parse(sessionStorage.getItem('menuGuid'))
    if(menu){
      return JSON.parse(sessionStorage.getItem('menuGuid')).menuGuid
    }
  }

  postService(url, data) {
    data.userGuid = this.userId.getValue();
    data.IPAddress = this.IPAddress;
    data.menuGuid = this.getMenuGuid();
    data.version = this.version;
    return this.http.post(environment.basePath + url, data)
  }
 
  postServiceIp(url, data) {
    data.userGuid = this.userId.getValue();
    data.IPAddress = this.IPAddress;
    data.menuGuid = this.getMenuGuid();
    data.version = this.version;
    return this.http.post(environment.basePath + url, data)

  }
  postReportService(url, data) {
    data.userGuid = this.userId.getValue();
    data.IPAddress = this.IPAddress;
    data.menuGuid = this.getMenuGuid();
    data.version = this.version;
    
    return this.http.post(environment.reportBasePath + url, data)
  }
  
  deleteService(url, data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: data
    };
    return this.http.delete(environment.basePath + url, httpOptions)
  }

  getUserData(){
    if (JSON.parse(sessionStorage.getItem('userData')) && JSON.parse(sessionStorage.getItem('userData')).data) {
       return JSON.parse(sessionStorage.getItem('userData')).data;
    }
  }
   

  sendMail(data) {
    const httpOptions = {
      headers: new HttpHeaders({ 
        'api_key' : 'bfe6e3acbfc65e9a1f763cf4ccf15c61',
        'Content-Type': 'application/json' 
      }),
    };
    return this.http.post('https://api.pepipost.com/V5.1/mail/send',data, httpOptions)
  }
  postCRMValidateService(url, data,tokenData) {
    
    let token = tokenData ? tokenData : '';
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    data.userGuid = this.userId.getValue();
    data.IPAddress = this.IPAddress;
    data.menuGuid = this.getMenuGuid();
    data.version = this.version;
    return this.http.post(environment.basePath + url, data, httpOptions)
  }
  putOrder(data){

    const httpOptions ={
      headers:new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-version': '2021-05-21',
        'x-client-id': '9777075ffcc67bd4ec156414b07779',
        'x-client-secret': '663cc7baeeca6ea227b301be976f4b18cb04bc11'
      })
    }
    return this.http.post("https://sandbox.cashfree.com/pg/orders",data,httpOptions)
  }

  dataURItoBlob(dataURI) {
    
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array]);    
    return blob;
 }

 private httpclient: HttpClient;


  postToken(url, data) {
    const headers= new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('correlation-id', '1041827a-9b94-433c-8e1d-ca8408a8fb1a')
    .set('cache-control', 'no-cache, no-store, max-age=0, must-revalidate')
    .set('pragma', 'no-cache')
    .set('expires', '0') 
    .set('Accept', '*/*') 
    return this.httpclient.post(url, data, {headers} )
  }

  postReg(url, data, res){
    const headers= new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('cache-control', 'no-cache, no-store, max-age=0, must-revalidate')
    .set('expires', '0') 
    .set('Accept', '*/*') 
    .set('Authorization', 'Bearer ' + res?.accessToken) 
    return this.httpclient.post(url, data, {headers} )
  }

  getReg(url, res){
    const headers= new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('cache-control', 'no-cache, no-store, max-age=0, must-revalidate')
    .set('expires', '0') 
    .set('Accept', '*/*') 
    .set('Authorization', 'Bearer ' + res?.abhaNoToken?.accessToken) 
    .set('X-Token', 'Bearer ' + res?.token) 
    .set('X-HIP-ID', 'HealthIdWeb' ) 
    .set('Accept-Language', 'en-US,en;q=0.9' ) 

    return this.httpclient.get(url, {headers})
  }

  getCommonApi(url, res){
    // const headers= new HttpHeaders()
    // .set('Content-Type', 'application/json')
    // .set('Access-Control-Allow-Origin', '*')
    // .set('cache-control', 'no-cache, no-store, max-age=0, must-revalidate')
    // .set('expires', '0') 
    // .set('Accept', '*/*') 
    // .set('X-HIP-ID', 'HealthIdWeb' ) 
    // .set('Accept-Language', 'en-US,en;q=0.9' ) 

    return this.http.get(url, res)
  }

  postUser(url,data, res){
    const headers= new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('cache-control', 'no-cache, no-store, max-age=0, must-revalidate')
    .set('expires', '0') 
    .set('Accept', '*/*') 
    .set('Authorization', 'Bearer ' + res?.token?.accessToken) 
    .set('T-Token', 'Bearer ' + res?.t_token) 
    return this.httpclient.post(url, data, {headers} )
  }

  getUserProfile(url, res){
    const headers= new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('cache-control', 'no-cache, no-store, max-age=0, must-revalidate')
    .set('expires', '0') 
    .set('Accept', '*/*') 
    .set('Authorization', 'Bearer ' + res?.token?.accessToken) 
    .set('X-Token', 'Bearer ' + res?.x_token) 
    return this.httpclient.get(url, {headers})
  }

  getUserProfile1(url, res){
    // console.log(res, "URL WIth Data --- 000000")
    const headers= new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('cache-control', 'no-cache, no-store, max-age=0, must-revalidate')
    .set('expires', '0') 
    .set('Accept', '*/*') 
    .set('Authorization', 'Bearer ' + this.data?.token?.accessToken) 
    .set('X-Token', 'Bearer ' + this.data?.x_token) 
    return this.httpclient.get(url, {headers})
  }

  chechValidation(fromDate, toDate){
    let numberofDays = 0;
    numberofDays = (new Date(toDate).getTime() - new Date(fromDate).getTime())/86400000
    console.log(numberofDays)
    if(numberofDays  == 0){
      return false
    }else{
      return true
    }
  }

  chechValidationForExcel(fromDate, toDate){
    let numberofDays = 0;
    numberofDays = (new Date(toDate).getTime() - new Date(fromDate).getTime())/86400000
    console.log(numberofDays)
    if(numberofDays<=30){
      return false
    }else{
      return true
    }
  }

  chechValidationMachineInterface(fromDate, toDate){
    let numberofDays = 0;
    numberofDays = (new Date(toDate).getTime() - new Date(fromDate).getTime())/86400000
    console.log(numberofDays)
    if(numberofDays<=14){
      return false
    }else{
      return true
    }
  }


  
  addUserData(data:any) 
  {
    data.userGuid = this.userId.getValue();
    data.IPAddress = this.IPAddress;
    data.menuGuid = this.getMenuGuid();
    data.version = this.version;
    
    return data;
  }



  apiResponse(dataVar: any): Observable<any>
  {
    
    this.addUserData(dataVar.payload);

    if (dataVar.config.method == "POST") 
    {
      return this.http.post(dataVar.config.url, dataVar.payload);
    
    } 

  }

}