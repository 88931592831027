<div class="common-dialog delete-confirmation">
    <div class="header">
    <div class="row">
        <div class="col-md-10">  
            <!-- <h1 class="mb-0">Text Editor</h1>            -->
        </div>
        <!-- <div class="col-md-1 mt-n1">
            <button mat-icon-button mat-dialog-close color="mat-primary"><mat-icon>close</mat-icon></button>
        </div> -->
    </div> 
</div>
<div class="modal-body">
    <quill-editor 
	[styles]="{height: '200px'}" 
	(onFocus)="focus($event)" 
	(onEditorChanged)="changedEditor($event)"
	(onBlur)="blur($event)" 
    (onEditorCreated)="created($event)"
	[(ngModel)]="content"
	(onContentChanged)="onContentChanged()"
></quill-editor>
</div> 
<div class="modal-footer">
    <button pButton  name="close" type="button" (click)="close()" label="Close" class="button-border mr-1" ></button>
    <button pButton  name="add" type="button" (click)="add()"  label="ADD" class="save-button"></button>
</div> 
</div>