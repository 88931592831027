<!--Add Remarks-->
<div class="common-dialog delete-confirmation">
   <div class="header">
      <div class="row">
          <div class="col-md-11">  
              <h1 class="mb-3">Add Remark</h1>           
          </div>
          <div class="col-md-1">
            <button mat-icon-button mat-dialog-close color="mat-primary"><mat-icon>close</mat-icon></button>
        </div>
      </div> 
  </div>
  <div class="modal-body p-0">
      <form [formGroup]="addRemarkForm" (ngSubmit)="AddRemark()">
        <div class="row">
            <div class="col-md-8 mb-3">
               <mat-form-field class="example-full-width w-100">
                  <mat-label>Test Name</mat-label>
                  <mat-select formControlName="testGuid" [disabled]="showName" require>
                     <mat-option *ngFor="let test of patientWiseTestList" [value]="test.testGuid">{{test.testname}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="errorHandling('testGuid','required')">
                     This Field is required
                 </mat-error>
               </mat-form-field>
            </div>
            <div class="col-md-4"></div>
             <div class="col-md-12">
              <mat-form-field class="example-full-width w-100">
                 <mat-label>Remarks</mat-label>
                 <textarea [readonly]="!addRemarkForm.get('testGuid').value" formControlName="remarks" maxlength="500" required matInput></textarea>
                 <mat-error *ngIf="errorHandling('remarks','required')">
                  This Field is required
              </mat-error>
              </mat-form-field>
           </div>
         </div> 
         <div class="row">
            <div class="modal-footer border-top-0 w-100 justify-content-end bg-white pb-0">    
              <button pButton label="Reset" (click)="reset()"  type="button" class="button-border mr-2"></button>
              <button pButton label="Add"  type="submit" class="save-button"></button>
           </div>
        </div>
      </form>
   </div>
</div>