<!--view text editor content Confirmation-->
<div class="common-dialog" *ngIf="previewData?._lstTestElement?.length;">
    <div class="header border-bottom">
        <div class="row">
            <div class="col-md-10">
                <h1 class="mb-0">Result Preview</h1>
            </div>
            <div class="col-md-1 mt-n1 text-right">
                <button mat-icon-button mat-dialog-close color="mat-primary">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <!-- <perfect-scrollbar thumbClass="custom-scrollbars"> -->
    <div class="modal-body" style="height: calc(100vh - 160px); overflow: hidden;overflow-y: scroll;">
        <div class="row">
            <div class="col-md-12 mt-4">
                <h4 class="test-title font-14">Patient Details</h4>
                <div class="patient-widget-col">
                    <div class="patient-info-col">
                        <span>Patient Name</span>
                        <span>{{previewData?.fname}}</span>
                    </div>
                    <div class="patient-info-col">
                        <span>Doctor Name</span>
                        <span>{{previewData?.doctorname}}</span>
                    </div>
                    <div class="patient-info-col">
                        <span>Doctor Contact Number</span>
                        <span>{{previewData?.doctorcontactno}}</span>
                    </div>
                    <div class="patient-info-col">
                        <span>PID</span>
                        <span>{{previewData?.pId}}</span>
                    </div>
                    <div class="patient-info-col">
                        <span>Visit ID</span>
                        <span>{{previewData?.visitId}}</span>
                    </div>
                    <div class="patient-info-col">
                        <span>Age</span>
                        <span>{{previewData?.year}}Y, {{previewData?.month}}M,
                            {{previewData?.days}}D</span>
                    </div>
                    <div class="patient-info-col">
                        <span>Gender</span>
                        <span>{{previewData?.gender}}</span>
                    </div>
                    <div class="patient-info-col">
                        <span>Mobile No.</span>
                        <span>{{previewData?.contactno}}</span>
                    </div>
                    <div class="patient-info-col">
                        <span>Email</span>
                        <!-- <span>{{previewData?.emailid}}</span> -->
                        <span class="trim-text" style="width: 180px;" pTooltip="{{previewData?.emailid}}"
                            tooltipStyleClass="tooltip-trim" tooltipPosition="bottom">
                            {{previewData?.emailid}}</span>
                    </div>
                    <div class="patient-info-col">
                        <span>Sample Collected</span>
                        <span>{{previewData?.samplecollectiontime}}</span>
                    </div>
                    <div class="patient-info-col">
                        <span>Outlet Name</span>
                        <span>{{previewData?.outletname}}</span>
                    </div>
                    <div class="patient-info-col">
                        <span>Outlet Contact Number</span>
                        <span>{{previewData?.outletcontactno}}</span>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngFor="let item of previewData?._lstTestElement">
            <div class="row ml-0 pr-3">
                <div class="col-md-12 mt-4 pl-0">
                    <h2 class="test-title font-14 border-bottom">Result</h2>
                </div>
                <div class="patient-widget-col" *ngFor="let sample of previewData?._lstTestSampleType">
                    <ng-container *ngIf="sample.testGuid == item.testGuid">
                        <div class="patient-info-col">
                            <span>Test Name</span>
                            <span>{{sample?.testName}}</span>
                        </div>
                        <div class="patient-info-col">
                            <span>Sample Type</span>
                            <span>{{sample?.sampletype}}</span>
                        </div>
                        <div class="patient-info-col">
                            <span>Department Name</span>
                            <span>{{sample?.deptname}}</span>
                        </div>
                        <div class="patient-info-col">
                            <span>Department Received</span>
                            <span>{{sample?.labdeptacceptdate}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row ml-0 pr-3">
                <div [innerHTML]="item?.result"></div>
            </div>
        </ng-container>

    </div>
    <!-- </perfect-scrollbar> -->
    <div class="modal-footer">
        <button pButton name="no" type="cancel" (click)="close()" label="Approve" class="button-border mr-1"></button>
    </div>
</div>

<div class="common-dialog" *ngIf="!previewData?._lstTestElement?.length">
    <div class="header">
        <div class="row">
            <div class="col-md-10">
                <h1 class="mb-0">Result Preview</h1>
            </div>
            <div class="col-md-1 mt-n1 text-right">
                <button mat-icon-button mat-dialog-close color="mat-primary">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <div class="modal-body">
            No data avaiable.....
        </div>
    </div>