<!--Delete Confirmation-->
<div *ngIf="!isCancel" class="common-dialog delete-confirmation">
    <div class="header">
    <div class="row">
        <div class="col-md-10">  
            <h1>Confirmation</h1>           
        </div>
        <div class="col-md-1">
            <button mat-icon-button mat-dialog-close color="mat-primary"><mat-icon>close</mat-icon></button>
        </div>
    </div> 
</div>
<div class="modal-body">
    <p>{{data.text}}</p>
</div> 
<div class="modal-footer">
    <button pButton  name="no" type="cancel" (click)="onNoClick()" label="No" class="button-border mr-1" ></button>
    <button pButton  name="yes" type="save" (click)="onYesClick()" label="Yes" class="save-button"></button>
</div> 
</div>


<!--Cancel Confirmation-->
<div *ngIf="isCancel" class="common-dialog delete-confirmation">
    <div class="header">
    <div class="row">
        <div class="col-md-10">  
            <h1 class="mb-0">Test Cancel Confirmation</h1>           
        </div>
        <div class="col-md-1 mt-n1">
            <button mat-icon-button mat-dialog-close color="mat-primary"><mat-icon>close</mat-icon></button>
        </div>
    </div> 
</div>
<div class="modal-body">
    <p>You have selected <b>{{data.cancelCount}} Tests to Cancel.</b> Are you sure want to cancel it?
    <br/>This action can be undone</p>
</div> 
<div class="modal-footer">
    <button pButton  name="no" type="cancel" (click)="onNoClick()" label="No, Don't Cancel" class="button-border mr-1" ></button>
    <button pButton  name="yes" type="save" (click)="onYesClick()" label="Yes, Cancel Tests" class="save-button"></button>
</div> 
</div>

