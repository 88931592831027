import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[masterCode]'
})
export class MasterCodeDirective {
  // Allow decimal numbers and negative values
  // private regex: RegExp = new RegExp('^[\s0-9A-Za-z][0-9A-Za-z \s]*$'); //Alpha numeric without special char and starting char not space
  private regex: RegExp = new RegExp('^[0-9A-Za-z-_\-_()&]+$');  // Only Alpha numeric and  - and _ special char
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // 
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
  
  @HostListener('drop', ['$event'])
  public ondrop(event: any):void
  {
    
    event.preventDefault();
  }

  @HostListener('paste', ['$event'])
  public onpaste(event: any):void
  {
    
    event.preventDefault();
  }
}