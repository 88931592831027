import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AccessioningService {

  constructor() { }

  public AccessioningSampleTabsCountSubject = new Subject();

  
//Sample Tab count Start
setAccessioningSampleTabsCount(data){
  this.AccessioningSampleTabsCountSubject.next(data);
  }
getAccessioningSampleTabsCount(){
return this.AccessioningSampleTabsCountSubject.asObservable()
}
//Sample Tab count End
  setPermission(data) {
    
    sessionStorage.setItem('abc', btoa(data));   //to set persmission
  }

  permission() {
    let permission = atob(sessionStorage.getItem('abc'));
    if (permission && permission == 'update') {
      return true
    }
    else if (permission && permission == 'view') {
      return false
    }
  }
}