import { Component, OnInit, Output,EventEmitter,Input, OnChanges } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

interface LatLng{
  lat:Number,
  lng:Number
}

@Component({
  selector: 'app-dragable-agm-map',
  templateUrl: './dragable-agm-map.component.html',
  styleUrls: ['./dragable-agm-map.component.css']
})
export class DragableAgmMapComponent implements OnInit,OnChanges {
  title: string = 'AGM project';
  latitude: number=28.5729847;
  longitude: number=77.32490430000001;
  zoom: number;
  address: string;
  private geoCoder;

  @Input()  latLongInputValue:any
  @Output()  agmMapData:EventEmitter<any> = new EventEmitter();
    constructor(
    private mapsAPILoader: MapsAPILoader

    ) { }

  ngOnInit(): void {
    this.loadMap();

  }
  
ngOnChanges(){
  
  
  if(this.latLongInputValue && this.latLongInputValue.lat && this.latLongInputValue.long){
    this.latitude =parseFloat(this.latLongInputValue.lat),
    this.longitude = parseFloat(this.latLongInputValue.long)
  }
}
  loadMap() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 10;
      });
    }
  }

  markerDragEnd($event: google.maps.MouseEvent) {
    
    this.latitude = $event.latLng.lat()
    this.longitude = $event.latLng.lng();
    
    let obj ={
      lat:this.latitude,
      lng:this.longitude
    }
    this.agmMapData.emit(obj)
    // this.getAddress(this.latitude, this.longitude);
  }

  // getAddress(latitude, longitude) {
  //   this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
  //     
  //     
  //     if (status === 'OK') {
  //       if (results[0]) {
  //         this.zoom = 12;
  //         this.address = results[0].formatted_address;
  //       } else {
  //         window.alert('No results found');
  //       }
  //     } else {
  //       window.alert('Geocoder failed due to: ' + status);
  //     }

  //   });
  // }


}
