import { Directive,ElementRef,HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphabetsOnly]'
})
export class AlphabetsOnlyDirective {

  key:any;
  private regex:RegExp = new RegExp('^[A-Za-z]+[A-Za-z ]*$')

  constructor(private el:ElementRef) { }

  @HostListener('keydown',['$event']) onKeydown(event:KeyboardEvent){
      this.key = event.keyCode;
      if((this.key >= 15 && this.key <= 64) || (this.key >= 123) || (this.key >= 96 && this.key <=105 )){
          event.preventDefault();
      }


    
  }

}
