<div class="common-dialog delete-confirmation">
    <div class="header">
    <div class="row">
        <div class="col-md-10">  
            <h1 class="mb-0">Confirmation</h1>
        </div>
        <div class="col-md-1">
            <button (click)="onNoClick()" mat-icon-button mat-dialog-close color="mat-primary"><mat-icon>close</mat-icon></button>
        </div>
    </div> 
</div>
<div class="modal-body">
    <p class="mb-0 font-15">{{data.text}}</p>
</div> 
<div class="modal-footer">
    <button *ngIf="data.type !== 'PAST' " pButton  name="no" type="cancel" (click)="onNoClick()" label="No" class="button-border mr-1" ></button>
    <button *ngIf="data.type !== 'PAST' " pButton  name="yes" type="save" (click)="onYesClick()" label="Yes" class="save-button"></button>
    <button *ngIf="data.type == 'PAST' " pButton  name="no" type="save" (click)="onNoClick()" label="Close" class="save-button"></button>
</div> 
</div>