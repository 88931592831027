import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PathkindService } from 'src/app/services/pathkind.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { API_PATH } from 'src/app/utility/reference/api-constant';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {
  otpForm:FormGroup
  apiConstant: any = API_PATH;
  sentResend:any = 'send'
  constructor(
    public service: PathkindService,
    private toaster: ToasterService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<VerifyOtpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    
    this.makeMaskContactNumber();
    this.builOtpForm();
  }

  makeMaskContactNumber(){
    
  }

  builOtpForm(){
      this.otpForm = this.formBuilder.group({
        otpGuid: [this.data.otpGuid],
        otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      });
  }

  public errorHandling(control: string, error: string) {
    return this.otpForm.controls[control].hasError(error);
  }

  close(Type): void {
    this.dialogRef.close(Type);
  }

  resend(){
      let data = {
        mobileno: this.data.contactNumber
      }
     this.service.postService(this.apiConstant.PATIENT_BASIC_INFO_RESEND_OTP, { Data: data }).subscribe(res => {
       if (res['statusCode'] === "0") {
        //  this.toaster.showSuccess(res['statusMessage'])
        this.sentResend = 'resend'
       } else {
         this.toaster.showError(res['statusMessage'])
       }
     }, error => {
       
       this.toaster.showError(error.error.Message);
     })
  }

  verify(){
    if(this.otpForm.invalid){
      // this.toaster.showInfo('Please Enter OTP.')
    }else {

    this.service.postService(this.apiConstant.PATIENT_BASIC_INFO_VERIFY_OTP, { Data: this.otpForm.value }).subscribe(res => {
      if (res['statusCode'] === "0") {
        this.toaster.showSuccess(res['statusMessage'])
        this.close('Verify');
      } else {
        this.toaster.showError(res['statusMessage'])
      }
    }, error => {
      
      this.toaster.showError(error.error.Message);
    })
  }
  }
}
