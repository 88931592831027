<quill-editor 
	[styles]="{height: '200px'}" 
	(onFocus)="focus($event)" 
	(onEditorChanged)="changedEditor($event)"
	(onBlur)="blur($event)" 
    (onEditorCreated)="created($event)"
	[(ngModel)]="content"
	(onContentChanged)="onContentChanged()"
></quill-editor>
<!-- <pre><code>{{ content }}</code></pre> -->



