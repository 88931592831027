import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-text-editor-content',
  templateUrl: './view-text-editor-content.component.html',
  styleUrls: ['./view-text-editor-content.component.css']
})
export class ViewTextEditorContentComponent implements OnInit {
  viewEditorDiv: HTMLElement;
  popupHeader:any = '';
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewTextEditorContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit(): void {
    if (this.data.viewFor == 'CustomRefRange') {
      this.viewEditorDiv = document.getElementById('viewEditorHtml');
      this.viewEditorDiv.innerHTML = this.data.html;
      this.popupHeader = 'View Custom Reference Range';
    }else if(this.data.viewFor == 'Authenticated'){
      this.viewEditorDiv = document.getElementById('viewEditorHtml');
      this.viewEditorDiv.innerHTML = this.data.html;
      this.popupHeader = 'View Result';
    }
  }
  close(): void {
    this.dialogRef.close('No');
  }
}
