import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-check-effective-date',
  templateUrl: './check-effective-date.component.html',
  styleUrls: ['./check-effective-date.component.css']
})
export class CheckEffectiveDateComponent implements OnInit {
 
  constructor(public dialogRef: MatDialogRef<CheckEffectiveDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
    console.log(this.data)
    }
  
  
    onNoClick(): void {
      this.dialogRef.close('NO');
    }
  
    onYesClick(){
      this.dialogRef.close('YES');
  
    }
}
