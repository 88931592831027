import { Injectable } from '@angular/core';
import { PathkindService } from './pathkind.service';
import { API_PATH } from 'src/app/utility/reference/api-constant';
import { ToasterService } from 'src/app/services/toaster.service'
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  apiConstant: any = API_PATH;
  public elementGuid$ = new Subject();
  public resultTypeGuid$ = new Subject();
  public interpretationList = new Subject();
  public isBasicInfoTab$ = new Subject<boolean>();
  public isSpinnerStop$ = new Subject<boolean>();
  public isInstrumentTab$ = new Subject<boolean>();
  public isSampleTab$ = new Subject<boolean>();
  public disableTabsSubject = new Subject();
  public resetBasicInfoSubject = new Subject();
  public resetOtherSubject = new Subject();
  public resetInstrumentSubject = new Subject();
  public resetSampleSubject = new Subject();
  public disableTestTabsSubject = new Subject();
  public resetTestBasicInfoSubject = new Subject();
  public resetTestElement = new Subject();
  public resetTestPerformingSubject = new Subject();
  public resetTestBookingSubject = new Subject();
  public resetTestInterpretationSubject = new Subject();
  public resetTestOtherSubject = new Subject();
  // public setTodaySearchData = new Subject();
  resetProfileBasicInfoSubject = new Subject();
  disableProfileTabsSubject = new Subject()
  resetProfileElement = new Subject();
  public resetTabSubject = new Subject();
  statusList: any;
  tempYearOrMonth:any;
  isKeyPressed:any;

  constructor(private service: PathkindService,
    private toaster: ToasterService,
    private datePipe: DatePipe) { }

    // setTodayData(data) {
    //   this.setTodaySearchData.next(data)
    // }
  
    // getTodayData() {
    //   return this.setTodaySearchData.asObservable();
    // }

    setReportBuilderAutoAPICall(data) {
      this.isSpinnerStop$.next(data)
    }
  
    getReportBuilderAutoAPICall() {
      return this.isSpinnerStop$.asObservable();
    }

  setBasicInfoTab(data) {
    this.isBasicInfoTab$.next(data)
  }

  getBasicInfoTab() {
    return this.isBasicInfoTab$.asObservable();
  }

  setInstrumentTab(data) {
    this.isInstrumentTab$.next(data);
  }

  getInstrumentTab() {
    return this.isInstrumentTab$.asObservable();
  }

  setSampleTab(data) {
    this.isSampleTab$.next(data)
  }

  getSampleTab() {
    return this.isSampleTab$.asObservable();
  }

  setElementGuid(data) {
    this.elementGuid$.next(data);
  }

  getElementGuid() {
    return this.elementGuid$.asObservable();
  }

  setResultTypeGuid(data) {
    this.resultTypeGuid$.next(data);
  }

  //Element
  getBasicInfoReset() {
    return this.resetBasicInfoSubject.asObservable();
  }

  setBasicInfoReset(data) {
    this.resetBasicInfoSubject.next(data);
  }

  getOtherReset() {
    return this.resetOtherSubject.asObservable();
  }

  setOtherReset(data) {
    this.resetOtherSubject.next(data);
  }

  getSampleReset() {
    return this.resetOtherSubject.asObservable();
  }

  setSampleReset(data) {
    this.resetOtherSubject.next(data);
  }

  getInstrumentReset() {
    return this.resetInstrumentSubject.asObservable();
  }

  setInstrumentReset(data) {
    this.resetInstrumentSubject.next(data);
  }

  getDisableTab() {
    return this.disableTabsSubject.asObservable();
  }

  setDisableTab(data) {
    this.disableTabsSubject.next(data);
  }

  //Test

  getTestBasicInfoReset() {
    return this.resetTestBasicInfoSubject.asObservable();
  }

  setTestBasicInfoReset(data) {
    this.resetTestBasicInfoSubject.next(data);
  }

  getTestElementReset() {
    return this.resetTestElement.asObservable();
  }

  setTestElementReset(data) {
    this.resetTestElement.next(data);
  }

  getTestPerformingReset() {
    return this.resetTestPerformingSubject.asObservable();
  }

  setTestPerformingReset(data) {
    this.resetTestPerformingSubject.next(data);
  }

  getTestBookingReset() {
    return this.resetTestBookingSubject.asObservable();
  }

  setTestBookingReset(data) {
    this.resetTestBookingSubject.next(data);
  }

  getTestInterpretationReset() {
    return this.resetTestInterpretationSubject.asObservable();
  }

  setTestInterpretationReset(data) {
    this.resetTestInterpretationSubject.next(data);
  }

  getTestOtherReset() {
    return this.resetTestOtherSubject.asObservable();
  }

  setTestOtherReset(data) {
    this.resetTestOtherSubject.next(data);
  }

  getTestDisableTab() {
    return this.disableTestTabsSubject.asObservable();
  }

  setTestDisableTab(data) {
    this.disableTestTabsSubject.next(data);
  }

  //
  //Profile
  getProfileBasicInfoReset() {
    return this.resetProfileBasicInfoSubject.asObservable();
  }

  setprofileBasicInfoReset(data) {
    this.resetProfileBasicInfoSubject.next(data);
  }



  getProfileElementReset() {
    return this.resetProfileElement.asObservable();
  }

  setProfileElementReset(data) {
    this.resetProfileElement.next(data);
  }

  getProfileDisableTab() {
    return this.disableProfileTabsSubject.asObservable();

  }

  setProfileDisableTab(data) {
    this.disableProfileTabsSubject.next(data);

  }
  //
  getResultTypeGuid() {
    return this.resultTypeGuid$.asObservable();
  }


  getDropDowns(ddlkey, successCall) {
    let request = {
      Data: {
        "ddlkey": ddlkey
      }
    }
    this.service.postService(this.apiConstant.MASTER_DROP_DOWNS, request).subscribe(
      res => {
        if (res['statusCode'] === "0") {
          if (res['data'] && res['data']['length'] > 0) {
            successCall(res);
          }
         
        }
      },
      error => {
        
        this.toaster.showError(error.error.Message);
      })
  }

  getCascadingDDL(ddlkey, ddlGuid, successCall,errorCall?) {
    let request = {
      Data: {
        "ddlkey": ddlkey,
        "ddlGuid": ddlGuid
      }
    }
    this.service.postService(this.apiConstant.MASTER_DROP_DOWNS, request).subscribe(
      res => {
        if (res['statusCode'] === "0") {
          if (res['data'] && res['data']['length'] > 0) {

            successCall(res);
          }
          else {
            this.toaster.showInfo('No data is availabe for this selection');
            errorCall()
          }
        }
      },
      error => {
        
        this.toaster.showError(error.error.Message);
      })
  }

  getCommonCascadingDdl(ddlkey, ddlGuid, successCall) {
    let request = {
      "Data": {
        "_lstkey": [
          {
            "keyvalue": ddlkey,
            "guidId": ddlGuid
          },
        ]
      }
    }
    this.service.postService(this.apiConstant.COMMON_DDL_LIST, request).subscribe(
      res => {
        if (res['statusCode'] === "0") {
          if (res['data']) {
            successCall(res);
          }
        }
        else {
          this.toaster.showError(res['statusMessage'])
        }
      },
      error => {
        
        this.toaster.showError(error.error.Message);
      })
  }

  getCommonDdlWithText(ddlkey, successCall) {
    let keyList: { keyvalue: string, Text: string }[] = [];
    if (ddlkey && ddlkey.length > 0) {
      ddlkey.forEach(element => {
        keyList.push({ "keyvalue": element, "Text": "" })
      });
    }
    let request = {
      "Data": {
        "_lstkey": keyList
      }
    }
    this.service.postService(this.apiConstant.COMMON_DDL_LIST, request).subscribe(
      res => {
        if (res['statusCode'] === "0") {
          if (res['data']) {
            successCall(res);
          }
        }
        else {
          this.toaster.showError(res['statusMessage'])
        }
      },
      error => {
        
        this.toaster.showError(error.error.Message);
      })
  }

  getCommonDdl(ddlkey, successCall) {
    let keyList: { keyvalue: string }[] = [];
    if (ddlkey && ddlkey.length > 0) {
      ddlkey.forEach(element => {
        keyList.push({ "keyvalue": element })
      });
    }
    let request = {
      "Data": {
        "_lstkey": keyList
      }
    }
    this.service.postService(this.apiConstant.COMMON_DDL_LIST, request).subscribe(
      res => {
        if (res['statusCode'] === "0") {
          if (res['data']) {
            successCall(res);
          }
        }
        else {
          this.toaster.showError(res['statusMessage'])
        }
      },
      error => {
        
        this.toaster.showError(error.error.Message);
      })
  }


  getCommonDdlWithGuid(ddlkey, successCall) {
    let keyList: { keyvalue: string, guidId: string }[] = [];

    if (ddlkey && ddlkey.length > 0) {
      ddlkey.forEach(element => {
        keyList.push({ "keyvalue": element.key, "guidId": element.guid })
      });
    }
    let request = {
      "Data": {
        "_lstkey": keyList
      }
    }
    this.service.postService(this.apiConstant.COMMON_DDL_LIST, request).subscribe(
      res => {
        if (res['statusCode'] === "0") {
          if (res['data']) {
            successCall(res);
          }
        }
        else {
          this.toaster.showError(res['statusMessage'])
        }
      },
      error => {
        
        this.toaster.showError(error.error.Message);
      })
  }
  setValidation(form, control) {
    form.get(control).setValidators(Validators.required);
    form.get(control).updateValueAndValidity();
  }

  transformDate(param) {
    // console.log(this.datePipe.transform(param, 'dd/MM/yyyy'))
    return this.datePipe.transform(param, 'dd/MM/yyyy');

  }
  
  transformDateMIS(param) {
    let date:any
    if(param){
      date = new Date((new Date(param)).getTime()-19800000);
    }
    // console.log(this.datePipe.transform(param, 'dd/MM/yyyy'))
    return this.datePipe.transform(date, 'dd/MM/yyyy');

  }

  transformDateTime(data) {
    return this.datePipe.transform(data,'dd/MM/yyyy HH:mm:ss')

  }

  setDate(event, form, control) {
    let today = new Date()
    let past = new Date(event);
    
    
    let years = this.calcDate(today, past);
    if (years < 18) {
      this.toaster.showInfo('Age should be greater than or equal to 18 years');
      form.get(control).setValue('');
    }
  }

  calcDate(date1, date2) {
    var diff = Math.floor(date1.getTime() - date2.getTime());
    var day = 1000 * 60 * 60 * 24;

    var days = Math.floor(diff / day);
    var months = Math.floor(days / 31);
    var years = Math.floor(months / 12);

    var message = date2.toDateString();
    message += " was "
    message += days + " days "
    message += months + " months "
    message += years + " years ago \n"
    return years;
    // return message
  }

  //Function to get Years, Months and Date from the Date of Birth

  getAgeInYearsMonthsDays(dob) {
    
    let now = new Date();
    var yearsAge, monthAge, dateAge;

    var dobYear = dob.getFullYear();
    var dobMonth = dob.getMonth();
    var dobDate = dob.getDate();

    var currentYear = now.getFullYear();
    var currentMonth = now.getMonth();
    var currentDate = now.getDate();

    //-----Get Years
    yearsAge = currentYear - dobYear;

    //-----Get Months
    if (currentMonth > dobMonth) {
      monthAge = currentMonth - dobMonth;
    }
    else {
      yearsAge--;
      monthAge = 12 + currentMonth - dobMonth;
    }

    //-----Get Date
    if (currentDate >= dobDate) {
      dateAge = currentDate - dobDate;
    } else {
      monthAge--;
      dateAge = 31 + currentDate - dobDate;
    }
    if(dateAge == 31){
      dateAge = 0;
      monthAge = monthAge + 1;
    }
    if(monthAge == 12){
      monthAge = 0;
      yearsAge = yearsAge+1;
    }
    return { years: yearsAge, months: monthAge, date: dateAge }
  }

  setMatInputDateFormat(strDate) {
    var parts = strDate.split('/');

    if (parts[1] && parts[1].length == 1) {
      parts[1] = '0' + parts[1];
    }
    var actualDate = new Date(parts[2] + '-' + parts[1] + '-' + parts[0]);
    return actualDate;

  }

  setPermission(data) {
    
    sessionStorage.setItem('abc', btoa(data));   //to set persmission
  }

  permission() {
    
    let permission = atob(sessionStorage.getItem('abc'));
    
    if (permission && permission == 'update') {
      return true
    }
    else if (permission && permission == 'view') {
      return false
    }

    else if (sessionStorage.getItem('abc') == undefined || sessionStorage.getItem('abc') == null) { //Case of ADD (It throws undefined..)
      return true;
    }
  }

  permissionView(permission,tabSaved){
    if (!permission && !tabSaved) { //Case of View if tab is not saved
      return false;
    }

   else if (!permission && tabSaved) { //Case of View if tab is saved
      return false;
    }

    else if (permission && tabSaved) { //Case of Edit when tab is saved
      return false;
    }

    else if (permission && !tabSaved) { //Case of Edit & Add when tab is not saved
      return true;
    }
  }
 
  getTabReset() {
    return this.resetTabSubject.asObservable();
  }

  setTabrReset(data) {
    this.resetTabSubject.next(data);
  }

  showStatusKey(successCall){
    this.service.postService(this.apiConstant.GET_STATUS_RESULT_ENTRY, { Data: {} }).subscribe(res => {
      if (res['statusCode'] === "0") {
        successCall(res['data']['_lstTestResultStatus'])
      } else {
        this.toaster.showError(res['statusMessage'])
      }
    }, error => {
      this.toaster.showError(error.error.Message);
    })
  }
  //Editor set reset functionality
  public EditorDataSubject = new Subject();

  getEditorData() {
    return this.EditorDataSubject.asObservable();
  }
  
  setEditorData(data) {
    this.EditorDataSubject.next(data);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if(charCode > 31 && (charCode < 48 || charCode > 57)){
      return false;
    }
    return true;
  }

  // currencyDataFormat(data){
  //   let currency = new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'INR'}).format(data)
    
  //   if(currency.charAt(0) === '-'){
  //     let abc = currency.slice(2)
  //     let xyz = '-' + abc
  //     return xyz
  //   }else{
  //     return currency.slice(1)
  //   }
  // }


  currencyDataFormat(data){
    if(!data || data === undefined){
      data = 0.00
    }
    let currency = new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'INR'}).format(data)
    
    if(currency.charAt(0) === '-'){
      let abc = currency.slice(2)
      let xyz = '-' + abc
      return xyz
    }else{
      return currency.slice(1)
    }
  }

  validateSpecialChar(event:any){
    if(/^[0-9A-Za-z]+$/.test(event.key)){
      return true
    }
    else{
      return false
    }
  }
  commonOnPaste(event){
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let trimmedText = pastedText.replace(/[^a-zA-Z0-9]/g, '');
    return trimmedText
  }

  validatenumberOnly(event:any){
    if(/^[0-9]+$/.test(event.key)){
      return true
    }
    else{
      return false
    }
  }
  commonNumberOnPaste(event){
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let trimmedText = pastedText.replace(/[^0-9]/g, '');
    return trimmedText
  }
  
  // GLOBAL Date Sorting Function For MIS

  globalDateSorting(checkOrder , list, datetimeKey, compareKey, successCall){
       let arrayDate = [];
       let array = [];
       let listToDisplay = [];

      if((list != null && list?.length >0)  && compareKey == 'dd/mm/yyyy') {  // logic to check only date key
        list.forEach(ele => {
         if(ele[`${datetimeKey}`]){
         let datepart = ''
         if(ele[`${datetimeKey}`].includes('-')){                             // If date format is dd-mm-yyyyy
          datepart = ele[`${datetimeKey}`].split("-")
         }
         else{
          datepart = ele[`${datetimeKey}`].split("/")                         // If date format is dd/mm/yyyyy
         }
        //  let datepart = ele['billingdate'] ? ele['billingdate'].split("/") : null;
         if(datepart){
         let dateObject = new Date(+datepart[2], +datepart[1] - 1, +datepart[0]); 
          ele['showDate'] = (new Date(dateObject).valueOf())
         arrayDate.push(ele)
         }
        }
        else{
          array.push(ele)
         }
         
       });
      }
      else if ((list != null && list?.length >0) && compareKey == 'dd/mm/yyyy:hh'){ // logic to check date time key
        list.forEach(ele => {
          let dateString = ele[`${datetimeKey}`],
          dateTimeParts = dateString.split(' '),
          timeParts = dateTimeParts[1].split(':'),
          dateParts = dateTimeParts[0].split('/'),
          dateTime;
          dateTime = (new Date(dateParts[2], parseInt(dateParts[1], 10) - 1, dateParts[0], timeParts[0], timeParts[1])).getTime();
          ele['showDate'] = dateTime
          if(dateTime){
            arrayDate.push(ele)
          }
          else{
            array.push(ele)
          }
        });
      }
      else{  // when list is empty and sorting is done
        return
      }
      
      // Ascending Order Sorting 
      if(checkOrder == 'ASC'){
      arrayDate.sort((a, b) => parseFloat(a.showDate) - parseFloat(b.showDate))
      listToDisplay = [...arrayDate, ...array]
      list = listToDisplay
      successCall(list)
      }
      // Descending Order Sorting
      else{
        arrayDate.sort((a, b) => parseFloat(b.showDate) - parseFloat(a.showDate))  
        listToDisplay = [...array, ...arrayDate]
        list = listToDisplay
        successCall(list)
      }

  }

}
